import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { createConfirmationPlannings } from '@/@core/queries/confirmation-planning'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'

export default function useConfirmationPlanningAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const userData = store.state?.userStore?.userData
  const isSubmitting = ref(false)

  function create(data) {
    isSubmitting.value = true
    const client_id = (userData.role !== 'admin') ? { link: userData.client.$oid } : null
    const payload = []
    data.role.value.forEach(r => {
      data.process.value.forEach(p => {
        payload.push({
          client_id,
          role: { link: r },
          process: { link: p },
          confirmationsPerYear: data.confirmationsPerYear.value
        })
      })
    })

    store
      .dispatch('confirmationPlanningStoreModule/createConfirmationPlanning', {
        query: createConfirmationPlannings,
        variables: { data: payload }
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.confirmation_planning_added'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.confirmation_planning_add_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-confirmation-planning-list' })
        isSubmitting.value = false
      })
  }

  return {
    create,
    isSubmitting,
  }
}
